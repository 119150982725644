/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useRef, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Layout, Layouts, Responsive, WidthProvider,
} from 'react-grid-layout'
import { RequestManager } from '@osrdata/app_core/dist/requests'
import Tab from 'components/DashboardTab/Tab'
import {
  deleteWidget, getAvailableWidgets, getBoard, patchWidget, postWidget,
} from 'reducers/boards/boards.thunk'
import { getZone } from 'reducers/zones/zones.thunks'
import { ParamsCreateWidget, ParamsUpdateWidget, Widget } from 'reducers/boards/types'
import zonesSelectors from 'reducers/zones/zones.selectors'
import boardsSelectors from 'reducers/boards/boards.selectors'
import { RootState } from 'reducers/store'
import Loader from 'components/Loader/Loader'
import { useRole } from 'utils/hooks'
import WidgetWrapper from './Widgets/Wrapper'
import Map from './Widgets/Map/Map'
import BarWidget from './Widgets/Bar/Bar'
import LineWidget from './Widgets/Line/Line'
import WidgetCreateModal from './Modals/WidgetCreate/WidgetCreateModal'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import './Dashboards.scss'
import BoxplotWidget from './Widgets/Boxplot/Boxplot'

const ResponsiveGridLayout = WidthProvider(Responsive)

const Dashboards = () => {
  const modalRequestManager = new RequestManager()
  const metricRequestManager = new RequestManager()
  const dispatch = useDispatch()
  const dashboardRef = useRef<HTMLDivElement>(null)
  const { id } = useParams() as { id: string }
  const zoneId = new URLSearchParams(useLocation().search).get('zone_id')
  const zone = useSelector(zonesSelectors.getZoneById(zoneId))
  const board = useSelector(boardsSelectors.getBoardById(id))
  const { loading: boardLoadingState } = useSelector((state: RootState) => state.boards)
  const [enablePositionAction, setEnablePositionAction] = useState(false)
  const [layouts, setLayouts] = useState<Layouts>()
  const [displayModal, setDisplayModal] = useState<'add' | 'filter'>(null)
  const { canModify } = useRole(zone)

  useEffect(() => {
    dispatch(getAvailableWidgets())
    dispatch(getBoard(id))
    dispatch(getZone(zoneId))
  }, [])

  const onLayoutChange = (_: Layout[], lays: Layouts) => {
    setLayouts(lays)
  }

  const handleCreateWidget = (widget: ParamsCreateWidget) => {
    // transform widget params array value in string value
    const widgetParams = widget.parameters.map(param => ({
      ...param,
      value: Array.isArray(param.value) ? param.value.join(',') : param.value,
    }))
    dispatch(postWidget({
      ...widget,
      boardId: id,
      position: {
        x: 0, y: (board?.widgets?.length || 0) * 5, w: 12, h: 5,
      },
      parameters: [
        ...widgetParams,
        {
          slug: 'zone_id',
          value: zoneId,
        },
      ],
    }))
  }

  const handlePatchPosition = () => {
    const lays = layouts?.lg || layouts?.md || layouts?.sm || layouts?.xs || []
    lays.map(({
      i, x, y, h, w,
    }) => ({
      widgetId: i,
      position: {
        x, y, h, w,
      },
    })).forEach(({ widgetId, position }) => {
      dispatch(patchWidget({ boardId: id, widgetId, position } as ParamsUpdateWidget))
    })
  }

  const handleDeleteWidget = (widgetId: string) => () => {
    dispatch(deleteWidget({ boardId: id, widgetId }))
  }

  const renderWidget = (widget: Widget) => {
    switch (widget.format_slug) {
      case 'bar-chart':
        return <BarWidget widget={widget} zone={zone} />
      case 'line-chart':
        return <LineWidget widget={widget} zone={zone} />
      case 'boxplot':
        return <BoxplotWidget widget={widget} zone={zone} />
      default:
        return <Map widget={widget} zone={zone} />
    }
  }

  if (boardLoadingState === 'pending' || boardLoadingState === 'idle') {
    return <Loader />
  }

  return (
    <div id="dashboards" ref={dashboardRef}>
      <Tab
        zoneId={zoneId}
        disableActions={!canModify}
        onSavePositions={handlePatchPosition}
        onAddAction={() => setDisplayModal('add')}
        enablePositionActions={enablePositionAction}
        toggleEnablePositionActions={() => setEnablePositionAction(prev => !prev)}
      />
      <ResponsiveGridLayout
        className="layout"
        autoSize
        useCSSTransforms
        measureBeforeMount
        layouts={layouts}
        onLayoutChange={onLayoutChange}
        isDraggable={enablePositionAction}
        isResizable={enablePositionAction}
        resizeHandles={enablePositionAction ? ['se'] : []}
      >
        {board?.widgets?.map(widget => (
          <div
            key={widget.id}
            data-grid={widget.position}
          >
            <WidgetWrapper
              boardId={board.id}
              widget={widget}
              readonly={!canModify}
              onDelete={handleDeleteWidget(widget.id)}
              enablePositionAction={enablePositionAction}
            >
              {renderWidget(widget)}
            </WidgetWrapper>
          </div>
        ))}
      </ResponsiveGridLayout>
      <WidgetCreateModal
        zone={zone}
        requestManager={modalRequestManager}
        metricRequestManager={metricRequestManager}
        displayed={displayModal === 'add'}
        handleClose={() => setDisplayModal(null)}
        handleSubmit={handleCreateWidget}
      />
    </div>
  )
}

export default Dashboards
